<template>
  <div id="float-whatsapp" class="active">
    <div
      class="float-wa d-flex align-items-center p-1 rounded-pill"
      @click="openChat"
    >
      <div
        class="
          float-wa-icon
          d-inline-flex
          align-items-center
          justify-content-center
          rounded-circle
        "
      >
        <img
          id="chat"
          src="@/assets/img/whiz-chat.svg"
          @mouseover="
            iconImageChange(require('@/assets/img/whiz-chat-hover.svg'), 'chat')
          "
          @mouseout="
            iconImageChange(require('@/assets/img/whiz-chat.svg'), 'chat')
          "
          alt=""
        />
      </div>
      <div class="d-inline-flex p-2">
        <strong id="text"> Chat with us! </strong>
      </div>
    </div>
    <span class="livechat-badge" v-if="totalUnreadCounter">{{
      totalUnreadCounter
    }}</span>
  </div>
</template>
<script>
export default {
  name: "FloatWhatsapp",
  computed: {
    dialogList() {
      return this.$store.getters.getDialogList;
    },
    totalUnreadCounter: function () {
      let total = 0;
      for (const val of Object.values(this.dialogList)) {
        total = total + val.unread_messages_count;
      }
      return total;
    },
  },
  methods: {
    iconImageChange(image, id) {
      var element = document.getElementById(id);
      element.src = image;
    },
    openChat() {
      document.getElementById("float-whatsapp").classList.toggle("active");
      document.getElementById("float-chat-window").classList.toggle("active");
    },
  },
};
</script>

<style scoped>
#float-whatsapp.active {
  position: fixed;
  bottom: 10px;
  right: 20px;
  display: block;
  z-index: 100;
  opacity: 1;
  transform: translateX(0px);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
#float-whatsapp {
  position: fixed;
  bottom: 10px;
  right: 20px;
  display: block;
  z-index: -1;
  opacity: 0;
  transform: translateX(200px);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.float-wa {
  background: #d89700;
}
.float-wa-icon img {
  width: 32px;
}
.float-wa-icon {
  background: white;
  width: 40px;
  height: 40px;
}
#text {
  color: white;
}
/* chat unread counter badge */
.livechat-badge {
  position: absolute;
  font-size: 12px;
  top: -15px; 
  right: -5px;
  padding: 5px 10px;
  border-radius: 50%;
  background: red;
  color: white;
}
</style>