<template>
  <div class="d-flex chat-container">
    <!-- DESKTOP VIEW -->
    <div id="float-chat-window">
      <button type="button" @click="closeChat" class="close">
        <img src="@/assets/img/ic-close.svg" alt="" />
      </button>
      <!-- if user already register quickblox -->
      <template v-if="$store.getters.getUserData.quickblox_id">
        <template v-if="$store.getters.getIsLoading['dialogList']">
          <div
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              w-100
            "
          >
            <div class="text-center">
              <img src="@/assets/img/chat-loading.gif" style="width: 170px" alt="" />
              <h3>Please wait</h3>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- Left Side -->
          <div class="left">
            <!-- Left Top Side -->
            <div class="dialog-search-merchant">
              <!-- <h5>Merchant List</h5> -->
              <div
                class="menu-bars align-self-center"
                href="javascript:void(0)"
                @click="toggleSideNav"
                ><span></span
              ></div>
              <div class="flex-grow-1">
                <h5>Chat ({{ Object.keys(dialogList).length }})</h5>
                <input
                  type="text"
                  class="form-control"
                  v-model="searchMerchantField"
                  placeholder="Search merchant..."
                />
              </div>
            </div>
            <!-- Left Bottom Side -->
            <div class="dialog-list custom-scrollbar">
              <div
                class="dialog-per-merchant"
                :class="{
                  selected: currentDialog && currentDialog._id === dialog._id,
                }"
                v-for="(dialog, i) in filteredDialogList"
                :key="i"
                @click="openDialog(dialog)"
              >
                <div class="dialog-avatar">
                  <img src="@/assets/img/my-profile-default.png" alt="" />
                </div>
                <div class="dialog-info">
                  <span class="dialog-name">
                    <b>
                      {{ dialog.name }}
                    </b>
                  </span>
                  <span class="dialog-last-message">
                    <b
                      v-if="
                        dialog.last_message &&
                        dialog.last_message.startsWith('WHIZPVL')
                      "
                    >
                      {{
                        dialog.last_message.includes("HSO")
                          ? "Order : " + dialog.last_message.split("#")[1]
                          : "Product : " + dialog.last_message.split("#")[3]
                      }}
                    </b>
                    <!-- kondisi kalau ada attachment masih belum bagus, harusnya pake dialog.photo != null -->
                    <b v-else-if="/\[attachment\]/i.test(dialog.last_message) || !dialog.last_message">
                      <img src="@/assets/img/image-preview.png" width="15px" alt="" />
                      Image
                    </b>
                    <b v-else>
                      {{ dialog.last_message }}
                    </b>
                  </span>
                </div>
                <div class="dialog-additional-info">
                  <span
                    class="dialog-unread-counter"
                    v-if="dialog.unread_messages_count"
                  >
                    {{ dialog.unread_messages_count }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="dialog-chat">
            <template v-if="currentDialog">
              <div class="dialog-merchant-info">
                <div
                  class="menu-bars align-self-center"
                  href="javascript:void(0)"
                  @click="toggleSideNav"
                  ><span></span
                ></div>
                <div
                  v-if="currentDialog && currentDialog.name"
                  class="dialog-avatar"
                >
                  <img src="@/assets/img/my-profile-default.png" alt="" />
                </div>
                <span class="dialog-info">
                  <span class="dialog-name">
                    <b style="color: darkslategray">
                      {{
                        currentDialog && currentDialog.name
                          ? currentDialog.name
                          : "Welcome to Whizliz Chat"
                      }}
                    </b>
                  </span>
                </span>
              </div>
              <div class="welcome__message" v-if="Object.keys(currentDialog).length === 0">
                <p>Please select user to start chatting.</p>
              </div>
              <div class="welcome__message text-black" v-else-if="$store.getters.getIsLoading['messageList']">
                <!-- <font-awesome icon="spinner" size="6x" spin /> -->
                <img src="@/assets/img/chat-loading.gif" style="width: 30%" alt="" />
              </div>
              <template v-else>
                <div class="dialog-chat-content">
                  <div
                    class="sticky-merchant-op-time-chat"
                    v-if="merchantData?.is_closed"
                  >
                    <fawesome-pro variant="far" size="lg" icon="exclamation-triangle" class="store-closed-notes-info" />
                    <div class="ml-2">
                      <b>Toko sedang tutup</b>
                      <div class="closed-store-desc">Hey, barang kamu akan di proses pada saat toko buka tanggal <b>{{ formatEndDateClose(merchantData.end_date_close) }}</b> ya! <b class="store-closed-notes-info" v-b-modal.modal-closed-shop-info-chat v-if="merchantData.closed_notes">Selengkapnya</b></div>
                    </div>
                  </div>
                  <div
                    class="sticky-merchant-op-time-chat"
                    v-b-modal.modal-operational-time
                    v-else-if="showTodayMerchantOperationalTime && todayOperationalTime"
                  >
                    <b-img width="13" :src="require('@/assets/img/icon_time_warning.svg')" alt=""></b-img>
                    <span class="ml-2">Jam Operasional: <b>{{ setOperationalTimeText(todayOperationalTime) }}</b></span>
                    <fawesome-pro variant="fas" icon="chevron-right" class="ml-auto" />
                  </div>
                  <div class="dialog-chat-messages-list custom-scrollbar">
                    <template v-for="(data, date) in messageList">
                      <div
                        class="message-content text-center sticky-top pb-3"
                        :key="date"
                      >
                        <div
                          class="d-inline-block message-date px-2 py-1 rounded"
                        >
                          {{ formatDate(date) }}
                        </div>
                      </div>
                      <div
                        class="message-content"
                        :class="{'text-center': message.sender_id === 0}"
                        v-for="message in messageList[date]"
                        :key="message._id"
                      >
                        <div class="d-inline-block message-system px-2 py-1 rounded" v-if="message.sender_id === 0">
                          {{ message.message }}
                        </div>
                        <div
                          class="chat-bubble"
                          :class="
                            message.sender_id ===
                            $store.getters.getUserData.quickblox_id
                              ? 'bubble-arrow-right self'
                              : 'bubble-arrow-left'
                          "
                          v-else
                        >
                          <span
                            v-if="
                              message.message &&
                              message.message.startsWith('WHIZPVL')
                            "
                            class="dialog-item-link"
                          >
                            <span class="dialog-avatar">
                              <img
                                :src="message.message.split('#')[4]"
                                class="h-100 bg-white"
                                @error="replaceByDefaultImage"
                                alt=""
                              />
                            </span>
                            <span class="dialog-info">
                              <span
                                class="private-link-name"
                                :class="
                                  message.message.includes('HSO')
                                    ? 'order-status'
                                    : ''
                                "
                              >
                                {{ message.message.split("#")[3] }}
                              </span>
                              <span
                                class="private-link-name"
                                v-if="message.message.includes('HSO')"
                              >
                                {{ message.message.split("#")[1] }}
                              </span>

                              <span class="private-link-price">
                                {{ message.message.split("#")[2] }}
                              </span>
                              <router-link
                                class="private-link-detail"
                                :to="
                                  message.message.includes('HSO')
                                    ? '/order-detail/' +
                                      message.message.split('#')[1]
                                    : '/product/' +
                                      (
                                        message.message.split('#')[3] +
                                        ' ' +
                                        message.message.split('#')[1]
                                      )
                                        .toLowerCase()
                                        .split(' ')
                                        .join('-')
                                "
                              >
                                View Detail
                              </router-link>
                            </span>
                          </span>
                          <span
                            v-else-if="
                              message.attachments &&
                              message.attachments.length > 0
                            "
                          >
                            <template
                              v-for="(attachment, i) in message.attachments"
                            >
                              <img
                                class="image-attachment"
                                @load="loadImagesEvent"
                                :src="attachment.src"
                                :key="i"
                                alt=""
                              />
                            </template>
                          </span>
                          <span class="message-text" v-else>
                            {{ message.message }}
                          </span>

                          <span class="timestamp-message">
                            {{ formatTimestamp(message.date_sent) }}
                            <img
                              v-if="message.sender_id === userData.quickblox_id"
                              :src="
                                message.status == 'read'
                                  ? require('@/assets/img/icon_read.png')
                                  : require('@/assets/img/icon_unread.png')
                              "
                              alt=""
                            />
                            <!-- <template v-if="message.sender_id === userData">
                              <img
                                v-if="message.read_ids.length === 2"
                                src="@/assets/img/icon_read.png"
                              />
                              <img v-else src="@/assets/img/icon_unread.png" />
                            </template> -->
                          </span>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="message-template">
                  <div v-for="(msgTemplate, i) in messageTemplates" :key="i" @click="useMessageTemplate(msgTemplate)">{{ msgTemplate }}</div>
                </div>
                <div class="message-preview-link" v-if="messagePreviewLink">
                  <div>
                    <img src="@/assets/img/ic-close.svg" class="delete-preview-link" alt="" @click="$store.dispatch('setMessagePreview', '')" />
                    <!-- preview link order -->
                    <template v-if="/WHIZPVLO\#/i.test(messagePreviewLink)">
                      <img id="preview-link-img" alt="" :src="messagePreviewLink.split('#')[4]" />
                      <label for="preview-link-img" class="pl-2">{{ messagePreviewLink.split('#')[1] }}</label>
                    </template>
                    <!-- preview link product -->
                    <template v-else-if="/WHIZPVL\#/i.test(messagePreviewLink)">
                      <img id="preview-link-img" alt="" :src="messagePreviewLink.split('#')[4]" />
                      <label for="preview-link-img" class="pl-2">{{ messagePreviewLink.split('#')[3] }}</label>
                    </template>
                  </div>
                </div>
                <form @submit.prevent="onSubmit">
                  <div class="dialog-chat-form d-flex align-items-center">
                    <div
                      class="message-field d-flex align-items-center border w-100"
                    >
                      <textarea
                        id="messageField"
                        rows="1"
                        data-min-rows="1"
                        placeholder="Enter your message here..."
                        class="form-control"
                        v-model.trim="messageField"
                        @keydown="typing"
                        @input="autoExpandMessageField"
                      />
                      <label for="file-input" class="px-2">
                        <img alt="" src="@/assets/img/icon_attach.png" />
                      </label>
                      <input
                        id="file-input"
                        type="file"
                        @input="uploadFile"
                        accept=".jpg, .jpeg, .png"
                      />
                    </div>

                    <label for="submit-message" class="pl-2">
                      <div class="send-button">
                        <img src="@/assets/img/icon_send.png" alt="" />
                      </div>
                    </label>
                    <input id="submit-message" type="submit" />
                  </div>
                </form>
              </template>
            </template>
            <template v-else>
              <div
                class="
                  h-100
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <h4>Please select the chat</h4>
                <a
                  class="d-block d-lg-none"
                  @click="toggleSideNav"
                  href="javascript:void(0)"
                  >Select Merchant</a
                >
              </div>
            </template>
          </div>
        </template>
      </template>

      <!-- if user click chat button in product detail and want to start quickblox chat for the first time -->
      <template
        v-else-if="!$store.getters.getUserData.quickblox_id && qbRegister"
      >
        <div
          class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            w-100
          "
        >
          <div class="text-center">
            <img class="mascot" src="@/assets/img/featured-chat.png" alt="" />
            <h4>Active your chat with click button below</h4>
            <button @click="registerQuickbloxUser" class="btn btn-wz-gold">
              Yes
            </button>
          </div>
        </div>
      </template>
      <!-- if user haven't register the quickblox id -->
      <template v-else-if="!$store.getters.getUserData.quickblox_id">
        <div
          class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            w-100
          "
        >
          <div class="text-center">
            <img class="mascot" src="@/assets/img/empty-chat.png" alt="" />
            <h3>No Chat Available</h3>
          </div>
        </div>
      </template>
    </div>
    <ModalOperationalTime :operationalTime="selectedMerchantOperationalTime" v-if="selectedMerchantOperationalTime" />
    <ModalClosedShopInfo id="modal-closed-shop-info-chat" :notes="merchantData.closed_notes" v-if="merchantData?.is_closed && merchantData?.closed_notes"/>
  </div>
</template>

<script>
import { toaster } from "@/_helpers";
import { qbHelper } from "@/_services";

import axios from "axios";
import moment from "moment";
const ModalOperationalTime = () => import("@/components/modals/ModalOperationalTime.vue");
const ModalClosedShopInfo = () => import("@/components/modals/ModalClosedShopInfo.vue");

export default {
  name: "Chat",
  props: {
    qbRegister: undefined,
    product: undefined,
    merchant: undefined,
    order: undefined,
  },
  data() {
    return {
      searchMerchantField: "",
      messageField: "",

      messageTemplates: [
        "Hai, barang ini ready ga?",
        "Bisa dikirim hari ini ga?",
        "Terima kasih!"
      ],

      todayOperationalTime: null,
      showTodayMerchantOperationalTime: false,
    };
  },
  components: {
    ModalOperationalTime,
    ModalClosedShopInfo
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    firebaseAuthKey() {
      return process.env.VUE_APP_FIREBASE_AUTH;
    },
    dialogList() {
      return this.$store.getters.getDialogList;
    },
    filteredDialogList() {
      return Object.values(this.dialogList).filter((dialog) => {
        return dialog.name
          .toLowerCase()
          .includes(this.searchMerchantField.toLowerCase());
      });
    },
    currentDialog() {
      return this.$store.getters.getCurrentDialog;
    },
    messageList() {
      return this.$store.getters.getMessageList;
    },
    messagePreviewLink() {
      return this.$store.getters.getMessagePreview;
    },
    selectedMerchantOperationalTime: {
      get() {
        return this.$store.getters.getCurrentDialogOpTime;
      },
      set(val) {
        this.$store.dispatch("setCurrentDialogOpTime", val);
      } 
    },
    merchantData: {
      get() {
        return this.$store.getters.getCurrentDialogMerchantData;
      },
      set(val) {
        this.$store.dispatch("setCurrentDialogMerchantData", val);
      }
    }
  },
  mounted() {
    //* Set value "todayOperationalTime" if current dialog operational time exist on mounted
    if (this.selectedMerchantOperationalTime) this.todayOperationalTime = this.getTodayOperationalTime(this.selectedMerchantOperationalTime);
  },
  methods: {
    registerQuickbloxUser() {
      axios
        .get(this.apiUrl + "users/chat/activate", {
          headers: {
            api_key: this.apiKey,
            token: this.$store.getters.getUserToken,
          },
        })
        .then((res) => {
          // add quickblox id to user data vuex
          let newUserData = this.$store.getters.getUserData;
          newUserData.quickblox_id = res.data.data.quickblox_id;
          this.$store.dispatch("setUserData", newUserData);
          qbHelper.createSession();
          this.createDialog();
        })
        .catch((err) => {
          toaster.make(
            "Oops, there's something wrong with livechat system",
            "danger"
          );
          console.error("Quickblox Register Error :", err);
        });
    },
    createDialog() {
      qbHelper.createDialog(
        this.merchant,
        this.product ?? this.order,
        this.product ? "product" : "order"
      );
    },
    openDialog(dialog) {
      if (dialog._id != this.currentDialog?._id) {
        this.messageField = "";
        this.$store.dispatch("setMessagePreview", "")
        this.$store.dispatch("setCurrentDialog", dialog);
      }

      const dialogElement = document.getElementsByClassName("left")[0];
      // mobile view purpose
      if (!dialogElement.classList.contains("hide")) dialogElement.classList.toggle("hide");
    },


    onSubmit() {
      if (!this.messageField && !this.messagePreviewLink) return

      if (this.messagePreviewLink) qbHelper.sendMessage(this.messagePreviewLink, null, this.messageField ? false : true); // 3rd param = if message field not exist -> push notification preview link, if message field exist -> push notification message field
      if (this.messageField) qbHelper.sendMessage(this.messageField, null);
      if (this.messageField && (this.showTodayMerchantOperationalTime || this.merchantData.is_closed) && !this.$store.getters.getSystemMessageSendStatus.merchantOperationalTime) {
        this.$store.dispatch({
          type: "setSystemMessageSendStatus",
          key: "merchantOperationalTime",
          value: true,
        });
        qbHelper.sendSystemMessage("Toko ini sedang tutup.\n Chat kamu akan dibalas pada saat jam operasional");
      }
      setTimeout(() => {
        qbHelper.scrollTo(document.querySelector(".dialog-chat-messages-list"), "bottom");
      }, 200);

      this.messageField = ""; // Clear message field after send message
      this.$store.dispatch("setMessagePreview", "") // Clear preview link after send message
    },

    // Upload File Handler Start
    async uploadFile(e) {
      const file = e.target.files[0];
      if (!file) return
      if (!/image\/jpg|png|jpeg/.test(file.type)) return toaster.make("Please select an image file (jpg, jpeg, png)", "danger", null, 2000);
      this.compressImage(file);
    },

    // sementara 1 gambar doang bisanya
    compressImage(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.filename = file.name; // set filename in FileReader (won't work for multiple/array file upload)

      reader.onload = (event) => {
        const imgElement = document.createElement("img");
        imgElement.src = event.target.result;
        
        imgElement.onload = (event) => {
          const canvas = document.createElement("canvas");
          const maxDimension = 600;

          const imgWidth = event.target.width;
          const imgHeight = event.target.height;
          

          if (imgWidth > maxDimension || imgHeight > maxDimension) {
            // Condition of which dimension is larger to scale the correct image size
            const largerHeight = imgHeight > imgWidth;
            const scaleSize =  maxDimension / (largerHeight ? imgWidth : imgHeight);
            canvas.width = largerHeight ? maxDimension : imgWidth * scaleSize;
            canvas.height = largerHeight ? imgHeight * scaleSize : maxDimension;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(event.target, 0, 0, canvas.width, canvas.height);
  
            const srcEncoded = canvas.toDataURL("image/jpeg");
            let compressedFile = this.dataURItoBlob(srcEncoded);
            compressedFile.filename = reader.filename;
            qbHelper.createAndUpload(compressedFile);
          } else {
            qbHelper.createAndUpload(file);
          }
        }
      }
    },

    // https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
    // Upload File Handler End

    closeChat() {
      this.$emit("setDefaultQbRegister");
      document.getElementById("float-chat-window").classList.toggle("active");
      document.getElementById("float-whatsapp").classList.toggle("active");
    },
    toggleSideNav() {
      document.getElementsByClassName("left")[0].classList.toggle("hide");
    },

    loadImagesEvent(e) {
      let img = e.target,
        container = document.querySelector(".dialog-chat-messages-list"),
        scrollHeight = container.scrollTop + img.offsetHeight;

      img.classList.add("loaded");

      if (container) {
        container.scrollTop = scrollHeight + 65;
      }
    },

    formatDate(value) {
      if (value) {
        // Get date now in ms timestamp
        const dateNow = moment.now();

        // Get value in ms timestamp
        const dateValue = parseInt(moment(value).format("x"));

        // Do the math
        const msPerDay = 1000 * 60 * 60 * 24;
        const msBetween = dateNow - dateValue;
        const days = Math.floor(msBetween / msPerDay);

        if (days === 0) {
          return "Today";
        } else if (days === 1) {
          return "Yesterday";
        } else {
          return moment(value).format("DD/MM/YYYY").toString();
        }
      }
    },

    formatTimestamp(value) {
      if (value) {
        return moment.unix(value).format("HH:mm");
      }
      return value;
    },

    // Format merchant store close end date
    formatEndDateClose(value) {
      moment.locale("id"); // Set date locale to indonesia
      return value ? moment.unix(value/1000).add(1, "days").format("DD MMMM YYYY") : value;
    },

    typing(e) {
      if (e.keyCode == 13 && !e.shiftKey) {
        e.preventDefault();
        this.onSubmit();
      }
    },

    // Auto Expand Message Field START
    getScrollHeight(elm) {
      var savedValue = elm.value;
      elm.value = "";
      elm._baseScrollHeight = elm.scrollHeight;
      elm.value = savedValue;
    },

    autoExpandMessageField() {
      const elm = document.getElementById("messageField");
      let minRows = parseInt(elm.getAttribute("data-min-rows"));
      !elm._baseScrollHeight && this.getScrollHeight(elm);

      elm.rows = minRows;
      let rows = Math.ceil((elm.scrollHeight - elm._baseScrollHeight) / 21);
      elm.rows = minRows + rows;
    },
    // Auto Expand Message Field END

    replaceByDefaultImage(img) {
      img.target.src = require("@/assets/img/product-default.png");
    },
    useMessageTemplate(val) {
      this.messageField = this.messageField + (this.messageField && this.messageField.slice(-1) != " " ? " " : "") + val
    },
    async getMerchantOperationalTime(qbId) {
      const res = await this.$api.cms.getMerchantOperationalTime(qbId);
      if (res.status === 200) {
        this.selectedMerchantOperationalTime = res.data.data;
        if (res.data.data) this.todayOperationalTime = this.getTodayOperationalTime(res.data.data);
      } else this.$helpers.toaster.make(res.data.message, "danger");
    },
    async getMerchantClosedStatus(qbId) {
      try {
        const res = await this.$api.cms.getMerchantClosedStatus(qbId);
        if (res.status === 200) {
          this.merchantData = res.data.data;
          if (!res.data.data.is_closed) this.getMerchantOperationalTime(qbId);
        }
        else this.$helpers.toaster.make(res.data.message, "danger");
      } catch (error) {
        console.error(error);        
      }
    },
    getTodayOperationalTime(data) {
      //* Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
      const currentDate = new Date().getDay();
      // Notes: el.id from data are 1 (Monday) - 7 (Sunday)
      // return data.find(el => el.id == currentDate == 0 ? 7 : currentDate);
      if (currentDate == 0) return data.find(el => el.id == 7);
      else return data.find(el => el.id == currentDate);
    },
    setOperationalTimeText(currOpTime) {
      if (!currOpTime.open && !currOpTime.closed) return "Libur";
      else if (currOpTime.open == "00:00" && currOpTime.closed == "23:59") return "Buka 24 Jam";
      else return `${currOpTime.open} - ${currOpTime.closed} WIB`
    },
  },
  watch: {
    currentDialog(val) {
      if (!val) return
      //* Reset System Message Send Status & Today Operational Time
      this.$store.dispatch({
        type: "setSystemMessageSendStatus",
        key: "merchantOperationalTime",
        value: false,
      });
      this.todayOperationalTime = null;
      const merchantQbId = val.occupants_ids.find(el => el != this.$store.getters.getUserData.quickblox_id);
      this.getMerchantClosedStatus(merchantQbId);
    },
    todayOperationalTime(val) {
      if (!val) return this.showTodayMerchantOperationalTime = false;
      const { open, closed } = val, currentHour = new Date().getHours();
      this.showTodayMerchantOperationalTime = [open && closed].some(el => el == null) || !(currentHour >= open.split(":")[0] && currentHour < closed.split(":")[0]);
    }
  }
};
</script>

<style scoped>
.chat-container {
  position: fixed;
  z-index: 1001;
  pointer-events: auto; /* make it clickable if chat container opened when tour guide is running */
}
.message-content {
  flex: 1 0;
  margin-top: 10px;
  padding-bottom: 8px;
  flex-wrap: nowrap;
  overflow: hidden;
}

.chat-bubble {
  float: left;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 25px;
  background-color: #fff;
  padding: 10px;
  position: relative;
  max-width: 75%;
}

.chat-bubble.bubble-arrow-left:before {
  content: "";
  /* width: 0px;
  height: 0px; */
  position: absolute;
  border-left: 19px solid white;
  border-top: 4px solid transparent;
  border-bottom: 12px solid transparent;
  bottom: -8px;
  left: 8px;
}

.chat-bubble.bubble-arrow-right:before {
  content: "";
  /* width: 0px;
    height: 0px; */
  position: absolute;
  border-top: 4px solid transparent;
  border-right: 19px solid #f8e1b0;
  border-bottom: 12px solid transparent;
  bottom: -8px;
  right: 8px;
}

.chat-bubble.self {
  float: right;
  background-color: #f8e1b0;
}

#float-chat-window {
  max-height: calc(100vh - 150px);
  height: 100%;
  width: 650px;
  display: flex;
  position: fixed;
  right: 20px;
  bottom: 10px;
  background-color: #f5f5f5;
  border-radius: 12px;
  z-index: -1;
  transform: translateX(700px);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 0;

  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}

#float-chat-window.active {
  max-height: calc(100vh - 150px);
  height: 100%;
  width: 650px;
  display: flex;
  position: fixed;
  right: 20px;
  bottom: 10px;
  background-color: #f5f5f5;
  border-radius: 12px;
  z-index: 100;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  transform: translateX(0px);
  opacity: 1;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}
img.mascot {
  width: 250px;
}
.close {
  opacity: 1;
  display: block;
  margin: 10px;
  width: 15px;
  position: absolute;
  right: 5px;
  z-index: 101;
}
.left {
  width: 40%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid darkslategray;
}
.dialog-chat {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 660px;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
}
.dialog-search-merchant {
  padding: 16px;
  border-bottom: 1px solid darkslategray;
  display: flex;
  /* flex-direction: column; */
}
.dialog-list {
  flex: 1 1 100%;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
}
.dialog-merchant-info {
  display: flex;
  padding: 16px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #4a4a4a;
  border-bottom: 1px solid darkslategray;
  /* flex: 0 0 87px; */
  box-sizing: border-box;
}
.dialog-chat-content {
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
}
.dialog-chat-messages-list {
  flex: 1 1 100%;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;
  padding: 10px 25px;
  width: 100%;
  max-height: 100%;
}
.dialog-chat-form {
  width: 100%;
  padding: 10px;
  border-top: 1px solid darkslategray;
}
.dialog-chat-form img {
  width: 25px;
}
.dialog-chat-form label {
  cursor: pointer;
  margin: auto;
}
.dialog-chat-form input {
  display: none;
}
.message-wrapper {
  flex-wrap: nowrap;
  margin: 0 0 16px 0;
}

.message-field {
  width: 80%;
  background: white;
  border-radius: 25px;
}
.message-field textarea {
  resize: none;
  border: none;
  border-radius: 25px;
  overflow: hidden;
  padding: 10px;
}

.send-button {
  padding: 7px;
  background-color: #d89700;
  border-radius: 25px;
}
/* DIALOG */
.dialog-per-merchant {
  padding: 16px;
  display: flex;
  flex-wrap: nowrap;
  text-decoration: none;
  border-bottom: 1px solid darkslategray;
  cursor: pointer;
}
.dialog-per-merchant.selected,
.dialog-per-merchant:hover {
  background: #eaaf28;
}
.dialog-avatar {
  flex: 0 0 48px;
  margin-right: 4px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid darkslategray;
}
.dialog-info {
  margin-left: 4px;
  flex: 1 1 100%;
  overflow: hidden;
}
.dialog-name {
  display: block;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 10px 6px 0;
}
.dialog-last-message {
  display: block;
  width: 100%;
  font-size: 13px;
  line-height: 15px;
  color: #808080;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dialog-additional-info {
  flex: 0 0 28px;
  text-align: right;
}
.dialog-unread-counter {
  min-width: 23px;
  background-color: #17d04b;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  color: #fff;
  text-align: center;
  display: inline-block;
}
.dialog-item-link {
  padding: 12px;
  display: flex;
  flex-wrap: nowrap;
  text-decoration: none;
}
.message-text {
  margin: 0 0 10px 0;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  overflow: hidden;
  white-space: pre-line;
  word-break: normal;
}
.order-status {
  font-size: 12px !important;
  color: #4caf50;
}

.private-link-name {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 10px 6px 0;
}

.private-link-price {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: red;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 10px 6px 0;
}

.private-link-detail {
  font-size: 14px;
  color: darkslategray;
}

.image-attachment {
  display: none;
  border-radius: 10px;
  padding: 2px;
}

.image-attachment.loaded {
  display: block;
}

.timestamp-message {
  text-align: right;
  float: right;
  font-size: 11px;
  opacity: 0.6;
  color: #4a4a4a;
  margin-left: 9px;
  margin-top: 10px;
}
.timestamp-message img {
  height: 11px;
}

.load-chat-list {
  position: absolute;
  bottom: 0;
  right: 25px;
  width: 70px;
}

.message-date {
  background-color: #e6e7e8;
  font-size: 12px;
  width: 80px;
}

.message-system {
  background-color: #e6e7e8;
  font-size: 12px;
  white-space: pre-line;
}

.message-template {
  position: relative;
  display: flex;
  background: transparent;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  /* margin: 10px 15px 0 15px; */
  padding: 10px;
  cursor: pointer;
}

.message-template div {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #fff;
  font-size: 12px;
  padding: 10px 6px;
  border: 1px solid black;
}

.message-template div:not(:last-child) {
  margin-right: 4px;
}

.message-template div:focus {
  background: #e2e2e2;
}

.message-preview-link {
  background: #fff;
  padding: 10px;
}

.message-preview-link div {
  display: flex;
  align-items: center;
  position: relative;
  background: #f5f5f5;
  padding: 8px;
}

.message-preview-link div > img.delete-preview-link {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 12px;
  cursor: pointer;
}

.message-preview-link div > img#preview-link-img {
  height: 60px;
}

.message-preview-link label {
  font-size: 14px;
}

/* Custom Scrollbar */
.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #808080;
}

.welcome__message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  text-align: center;
  height: 100%;
  /* color: #778594; */
  color: darkslategray;
  user-select: none;
}

/* Mobile Responsivity */
@media only screen and (max-width: 992px) {
  #float-chat-window {
    max-height: 80vh;
    width: 90vw;
    height: 73vh;
  }
  #float-chat-window.active {
    max-height: 80vh;
    width: 90vw;
    height: 73vh;
  }

  .left {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    overflow: hidden;
    border-right: 1px solid darkslategray;
    z-index: 100;
    border-radius: 12px;
  }
  .left.hide {
    display: none;
  }
  .menu-bars {
    width: 25px;
    display: block;
    padding: 0;
    margin: 0px 15px 0px 5px;
    position: relative;
    left: 0;
  }
  .menu-bars span,
  .menu-bars:before,
  .menu-bars:after {
    height: 2px;
    content: "";
    display: block;
    background: #000;
    margin: 6px 0;
  }
  img.mascot {
    width: 225px;
  }
}
.sticky-merchant-op-time-chat {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  background: #F3E8D4;
  padding: .25rem .75rem;
}

.sticky-merchant-op-time-chat .closed-store-desc {
  margin-top: 2px;
}
</style>